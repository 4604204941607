import { CloseCircleOutlined, UserOutlined } from '@ant-design/icons'
import {
  EFeedbackChannelMap,
  EFeedbackDepartmentMap,
  EFeedbackLevelTypeMap,
  EFeedbackStatusMap,
} from 'app/api/operation/general-feedback-customer/contant'
import {
  EFeedbackDepartment,
  ICustomerFeedback,
  IResolveCustomerFeedback,
} from 'app/api/operation/general-feedback-customer/modal'
import {
  SvgBuilding05Icon,
  SvgCalendarIcon,
  SvgFile05Icon,
  SvgMonitor02Icon,
  SvgNotificationMessageIcon,
  SvgNotificationTextIcon,
  SvgRows02Icon,
  SvgStar01Icon,
  SvgStarWhiteIcon,
} from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { notificationController } from 'app/controllers/notification-controller'
import { useResolveCustomerFeedback } from 'app/react-query/hook/general-feedback-customer'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { DateUtil, FORMAT_DD_MM_YYYY } from 'parkway-web-common'
import { ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { FeedbackTypeItem, geColorFeedback, StarWrapper } from '../../hook'
import dayjs, { Dayjs } from 'dayjs'

const InfoComp = ({
  icon,
  label,
  value,
}: {
  icon: ReactNode
  label: string
  value?: string | ReactNode
}) => {
  return (
    <BaseRow gutter={[8, 0]} align={'top'}>
      <BaseCol style={{ paddingTop: 4 }}>{icon}</BaseCol>
      <BaseCol>
        <BaseSpace size={4}>
          <BaseText
            children={label}
            style={{ color: '#2C3A4B' }}
            fontSize="xxxs"
          />
          <BaseText children={value} fontWeight="medium" fontSize="xxs" />
        </BaseSpace>
      </BaseCol>
    </BaseRow>
  )
}

const DetailFeedbackComp = ({
  icon,
  label,
  value,
}: {
  icon: ReactNode
  label: string
  value?: string | ReactNode
}) => {
  return (
    <BaseSpace size={8}>
      <BaseRow gutter={[8, 0]} align={'middle'}>
        <BaseCol>{icon}</BaseCol>
        <BaseCol>
          <BaseText
            children={label}
            style={{ color: '#2C3A4B' }}
            fontSize="xxxs"
          />
        </BaseCol>
      </BaseRow>
      <BaseText children={value} fontWeight="medium" fontSize="xxs" />
    </BaseSpace>
  )
}

interface IForm extends IResolveCustomerFeedback {
  resolveAtDayJs?: Dayjs
}

export const UpdateIdentifiedModal = ({
  record,
}: {
  record?: ICustomerFeedback
}) => {
  const refModal = useRef<IRefModal>({})
  const [form] = BaseForm.useForm<IForm>()
  const [tags, setTags] = useState<EFeedbackDepartment[]>([])

  const { mutateAsync: resolve, isLoading } = useResolveCustomerFeedback()

  const onHide = () => {
    refModal.current?.hide?.()
  }

  const onOpen = () => {
    refModal.current?.open?.()
  }

  const onPressSubmit = async (values?: IForm) => {
    if (!record?._id) {
      notificationController.error({
        message: 'Không tìm thấy feedback',
      })
      return
    }
    const payload: IForm = {
      departments: tags,
      resolveAt: values?.resolveAtDayJs?.toISOString(),
      status: values?.status,
      resolveMethod: values?.resolveMethod,
      result: values?.result,
    }
    const res = await resolve({ id: record?._id, payload })
    if (res) {
      notificationController.success({
        message: 'Cập nhật thành công',
      })
      onHide()
    }
  }

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        resolveAtDayJs: record?.resolveAt
          ? dayjs(record?.resolveAt)
          : undefined,
        status: record?.status,
        resolveMethod: record?.resolveMethod,
        result: record?.result,
      })
      setTags(record?.departments ?? [])
    }
  }, [record])

  return (
    <ModalComponent
      ref={refModal}
      titleModal="Cập nhật"
      isLoadingConfirm={isLoading}
      buttonOpenCustom={
        <BaseButton type="text" onClick={onOpen}>
          <BaseText children={'Cập nhật'} fontWeight="medium" />
        </BaseButton>
      }
      isDisableSubmit={!tags.length}
      renderContent={
        <ContentWrapper>
          <BaseCol xl={8}>
            <InfoSection>
              <BaseSpace size={8}>
                <BaseText
                  children={'Thông tin chung'}
                  fontWeight="medium"
                  style={{ color: '#365B7E' }}
                />
                <InfoComp
                  icon={<UserOutlined rev={undefined} />}
                  label={'Họ tên KH'}
                  value={record?.patientName}
                />
                <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
                  <BaseCol xl={12}>
                    <InfoComp
                      icon={<SvgCalendarIcon />}
                      label={'Ngày tiếp nhận feedback'}
                      value={DateUtil.formatDate(
                        record?.createdAt ?? '',
                        FORMAT_DD_MM_YYYY,
                      )}
                    />
                  </BaseCol>
                  <BaseCol xl={12}>
                    <InfoComp
                      icon={<SvgBuilding05Icon />}
                      label={'Phòng khám'}
                      value={record?.locationName}
                    />
                  </BaseCol>
                </BaseRow>

                <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
                  <BaseCol xl={12}>
                    <InfoComp
                      icon={<SvgMonitor02Icon />}
                      label={'Kênh feedback'}
                      value={
                        record?.channel
                          ? EFeedbackChannelMap?.[record?.channel]?.label
                          : '-'
                      }
                    />
                  </BaseCol>
                  <BaseCol xl={12}>
                    <InfoComp
                      icon={<SvgFile05Icon />}
                      label={'Phân loại mức độ'}
                      value={
                        <BaseSpace size={4}>
                          {record?.levelTypes?.map(item => {
                            return EFeedbackLevelTypeMap?.[item]?.label
                          })}
                        </BaseSpace>
                      }
                    />
                  </BaseCol>
                </BaseRow>

                <InfoComp
                  icon={<SvgRows02Icon />}
                  label={'Phân loại nội dung feedback'}
                  value={
                    <BaseRow gutter={[8, 8]} align="middle">
                      {record?.contentTypes?.map(item => {
                        return (
                          <BaseCol>
                            <FeedbackTypeItem type={item} />
                          </BaseCol>
                        )
                      })}
                    </BaseRow>
                  }
                />
              </BaseSpace>

              <LineDividerStyled />

              <BaseSpace size={8}>
                <BaseText children={'Chi tiết phản hồi'} fontWeight="medium" />

                <DetailFeedbackComp
                  icon={<SvgStar01Icon />}
                  label={'Điểm đánh giá'}
                  value={
                    record?.rate ? (
                      <StarWrapper
                        $backgroundColor={
                          convertedVariables[
                            geColorFeedback(record?.rate).background
                          ]
                        }
                        gutter={8}
                        align={'middle'}
                        justify={'center'}
                      >
                        <BaseCol>
                          <BaseText
                            children={record?.rate}
                            fontWeight="semibold"
                            textAlign="center"
                            colorText={'backgroundColor'}
                          />
                        </BaseCol>
                        <SvgStarWhiteIcon />
                      </StarWrapper>
                    ) : (
                      '-'
                    )
                  }
                />

                <DetailFeedbackComp
                  icon={<SvgNotificationTextIcon />}
                  label={'Nội dung feedback'}
                  value={
                    <FeedbackContent>
                      <BaseText children={record?.dynamicContent} />
                    </FeedbackContent>
                  }
                />

                <DetailFeedbackComp
                  icon={<SvgNotificationMessageIcon />}
                  label={'Ghi chú'}
                  value={
                    <FeedbackContent>
                      <BaseText children={record?.note} />
                    </FeedbackContent>
                  }
                />
              </BaseSpace>
            </InfoSection>
          </BaseCol>
          <FormSection xl={16}>
            <BaseForm form={form} onFinish={onPressSubmit}>
              <FormContent>
                <BaseForm.Item
                  required
                  label={'Phương án phản hồi KH'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập phương án phản hồi KH',
                    },
                  ]}
                  name={'resolveMethod'}
                >
                  <BaseInput.TextArea
                    placeholder="Nhập nội dung"
                    autoSize={{ minRows: 3, maxRows: 20 }}
                  />
                </BaseForm.Item>
                <BaseForm.Item label={'Kết quả'} name={'result'}>
                  <BaseInput.TextArea
                    placeholder="Nhập nội dung"
                    autoSize={{ minRows: 3, maxRows: 20 }}
                  />
                </BaseForm.Item>
                <BaseRow gutter={16} align={'top'} justify={'space-between'}>
                  <BaseCol xl={12}>
                    <BaseForm.Item
                      required
                      label={'Ngày giải quyết'}
                      name={'resolveAtDayJs'}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn ngày giải quyết',
                        },
                      ]}
                    >
                      <BaseDatePicker
                        format={FORMAT_DD_MM_YYYY}
                        placeholder="Chọn ngày giải quyết"
                        disabledDate={current => {
                          return current < dayjs(record?.receptionAt)
                        }}
                      />
                    </BaseForm.Item>
                  </BaseCol>
                  <BaseCol xl={12}>
                    <BaseForm.Item
                      required
                      label={'Trạng thái'}
                      name={'status'}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn trạng thái',
                        },
                      ]}
                    >
                      <BaseSelect
                        placeholder="Chọn trạng thái"
                        options={Object.values(EFeedbackStatusMap).map(
                          item => ({
                            label: item.label,
                            value: item.value,
                          }),
                        )}
                      />
                    </BaseForm.Item>
                  </BaseCol>
                </BaseRow>

                <BaseForm.Item required label={'Bộ phận liên quan'}>
                  <BaseSpace>
                    <TagContainer gutter={[16, 8]}>
                      {tags.map(item => {
                        return (
                          <BaseCol>
                            <TagItem gutter={8} align={'middle'}>
                              <BaseCol>
                                <BaseText
                                  children={
                                    EFeedbackDepartmentMap?.[item]?.label
                                  }
                                  fontSize="xxxs"
                                />
                              </BaseCol>
                              <BaseCol>
                                <CloseCircleOutlined
                                  rev={undefined}
                                  style={{
                                    width: 12,
                                    height: 12,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setTags(tags.filter(tag => tag !== item))
                                  }}
                                />
                              </BaseCol>
                            </TagItem>
                          </BaseCol>
                        )
                      })}
                      {tags?.length === 0 && (
                        <BaseText children={'Tag'} fontSize="xxxs" />
                      )}
                    </TagContainer>
                  </BaseSpace>
                </BaseForm.Item>

                <BaseSpace size={8}>
                  <BaseText children={'Chọn tag'} fontSize="xxxs" />
                  <BaseRow gutter={[8, 8]} align={'middle'}>
                    {Object.values(EFeedbackDepartmentMap).map(item => {
                      const isSelected = tags.includes(item.value)
                      return (
                        <BaseCol
                          onClick={() => {
                            if (!isSelected) {
                              setTags(tags.concat(item.value))
                            }
                          }}
                        >
                          <TagOption $isSelected={isSelected}>
                            <BaseText
                              children={item.label}
                              style={{
                                color: isSelected ? '#667085' : '#1D2939',
                              }}
                            />
                          </TagOption>
                        </BaseCol>
                      )
                    })}
                  </BaseRow>
                </BaseSpace>
              </FormContent>
            </BaseForm>
          </FormSection>
        </ContentWrapper>
      }
      justifyButtons="end"
      handleSubmit={form.submit}
      widthModal={1200}
      isShowLineBottom={false}
      isShowLineTop={false}
      paddingContent={'0px'}
    />
  )
}

const LineDividerStyled = styled.div`
  border-top: 1px solid ${convertedVariables.neutralBlack9Color};
`

const ContentWrapper = styled(BaseRow)`
  border-top: 1px solid #f2f4f7;
  border-bottom: 1px solid #f2f4f7;
`

const InfoSection = styled(BaseSpace)`
  padding: 16px;
`

const FormSection = styled(BaseCol)`
  border-left: 1px solid #f2f4f7;
  background-color: #fcfcfc;
`

const FormContent = styled(BaseSpace)`
  padding: 16px;
`

const FeedbackContent = styled.div`
  background-color: #f9fafb;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ebeef2;
`

const TagContainer = styled(BaseRow)`
  background-color: #f9fafb;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ebeef2;
  min-height: 50px;
  margin-inline: 0px !important;
`

const TagItem = styled(BaseRow)`
  border-radius: 20px;
  padding: 2px 8px;
  border: 1px solid #ebeef2;
  cursor: pointer;
  background-color: white;
  margin-inline: 0;
`

const TagOption = styled.div<{ $isSelected: boolean }>`
  background-color: ${props => (props.$isSelected ? '#F2F4F7' : 'white')};
  border-radius: 20px;
  padding: 2px 8px;
  border: 1px solid #ebeef2;
  cursor: pointer;
`
